/** @format */

import { ref, watch, computed } from '@vue/composition-api'

import store from '@/store'

export default function useInvoicesList(proxy) {
  const isLoading = ref(false)
  const refInvoiceListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    {
      key: 'id',
      label: '#',
      thStyle: {
        width: '80px',
      },
    },
    {
      key: 'title',
      label: '标题',
      sortable: false,
      thStyle: {
        width: '180px',
      },
    },
    {
      key: 'content',
      label: '内容',
      sortable: false,
      thStyle: {
        width: '580px',
      },
    },
    {
      key: 'created_at',
      label: '添加时间',
      sortable: false,
      thStyle: {
        width: '120px',
      }
    },
    { key: 'actions', label: '操作' },

  ]
  const perPage = ref(100)(100)
  const pageSize = ref(100)
  const totalInvoices = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const isSortDirDesc = ref(true)
  const statusFilter = ref(null)

  const dataMeta = computed(() => {
    const localItemsCount = refInvoiceListTable.value ? refInvoiceListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalInvoices.value,
    }
  })

  const refetchData = () => {
    refInvoiceListTable.value.refresh()
  }

  watch([currentPage, perPage, searchQuery], () => {
    pageSize.value = perPage.value
    refetchData()
  })

  // 数据获取
  const fetchInvoices = (ctx, callback) => {
    store
      .dispatch('course-module/fetchInvoices', {
        pageSize: pageSize.value,
        page: currentPage.value,
        title: searchQuery.value,
      })
      .then(response => {
        const { list, total } = response.data
        callback(list)
        totalInvoices.value = total
      })
  }
  // 数据删除
  const confirmDelete = id => {
    store.dispatch('course-module/deleteInvoices', id).then(response => {
      if (response.code == 0) {
        proxy.$bvToast.toast(response.msg, {
          title: response.msg,
          variant: 'success',
          solid: true,
        })
        refetchData()
      } else {
        proxy.$bvToast.toast(response.msg, {
          title: response.msg,
          variant: 'danger',
          solid: true,
        })
      }
    })
  }

  return {
    fetchInvoices,
    tableColumns,
    perPage,
    currentPage,
    totalInvoices,
    dataMeta,
    perPageOptions,
    searchQuery,
    isSortDirDesc,
    refInvoiceListTable,

    statusFilter,
    isLoading,
    refetchData,
    confirmDelete,
  }
}
